<template>
  <div>
    <!-- Form Modal -->
    <b-modal id="modal-society-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="sm" @ok="submit">
      <!-- Custom Modal Header -->
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5>Register SubZone</h5>

        <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
      </template>

      <!-- Custom Modal Footer -->
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
          <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
          <span v-if="!saving">Save</span>
          <span v-if="saving">Saving...</span>
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="cancel()"> Cancel </b-button>
      </template>

      <!-- Data Form in Modal Body -->
      <template #default="{}">
        <validation-observer ref="dataForm" #default="{ invalid }">
          <b-form ref="form" @submit.stop.prevent="handleCreate">
            <b-row>
              <!-- <b-col cols="12">
                                <b-form-group labe-for="location_id" label="Location">
                                    <validation-provider #default="{ errors }" name="Location" rules="required">
                                        <b-form-input
                                            id="location_id"
                                            v-model="selections[selections.length - 1].model.name"
                                            name="location"
                                            :state="errors.length > 0 ? false : null"
                                            size="sm"
                                            readonly
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{ serverErrors.location_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col> -->

              <b-col cols="12" md="6" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections" :key="toSelect.level">
                <label>{{ toSelect.label }}</label>
                <b-form-select size="sm" v-model="toSelect.model" @change="changeFilters(toSelect.model, index)">
                  <b-form-select-option :value="null">Select...</b-form-select-option>
                  <b-form-select-option v-for="location in toSelect.locations" :key="location.id" :value="location">
                    {{ location.name.toUpperCase() }}
                  </b-form-select-option>
                </b-form-select>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="3">
                <b-form-group labe-for="code" label="Code">
                  <validation-provider #default="{ errors }" name="Code" rules="">
                    <b-form-input id="code" v-model="society.code" name="code" :state="errors.length > 0 ? false : null" size="sm" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.code">{{ serverErrors.code[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="9">
                <b-form-group labe-for="name" label="Name">
                  <validation-provider #default="{ errors }" name="Name" rules="required">
                    <b-form-input id="name" v-model="society.name" name="name" :state="errors.length > 0 ? false : null" size="sm" />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small class="text-danger" v-if="serverErrors && serverErrors.name">{{ serverErrors.name[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <!-- <b-card>
                    {{ society }}
                </b-card> -->
      </template>
    </b-modal>
    <!-- ./Form Modal -->

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filter Location</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections" :key="toSelect.level">
            <label>{{ toSelect.label }}</label>
            <b-form-select size="sm" v-model="toSelect.model" @change="changeFilters(toSelect.model, index)">
              <b-form-select-option :value="null">Select...</b-form-select-option>
              <b-form-select-option v-for="location in toSelect.locations" :key="location.id" :value="location">
                {{ location.name.toUpperCase() }}
              </b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- ./Filters -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50 select-size-sm" />
            <label>entries</label>
          </b-col>
          <!-- ./Per Page -->

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." size="sm" />
              <b-button variant="primary" size="sm" :disabled="selections.length < 1 || !selections[selections.length - 1].model" @click="invokeCreateForm()">
                <span class="text-nowrap">Register SubZone</span>
              </b-button>
            </div>
          </b-col>
          <!-- ./Search -->
        </b-row>
        <!-- ./Table Top -->
      </div>

      <!-- Table -->
      <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found" :sort-desc.sync="isSortDirDesc">
        <!-- Column: Serail Number -->
        <template #cell(sno)="row">
          {{ row.index + from }}
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :text="avatarText(data.item.fullName)" :variant="`light-${resolveUserRoleVariant(data.item.role)}`" :to="{ name: 'apps-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link :to="{ name: 'apps-users-view', params: { id: data.item.id } }" class="font-weight-bold d-block text-nowrap">
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon :icon="resolveUserRoleIcon(data.item.role)" size="18" class="mr-50" :class="`text-${resolveUserRoleVariant(data.item.role)}`" />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
            <span v-if="data.item.status === 0">Closed</span>
            <span v-else-if="data.item.status === 1">Active</span>
            <span v-else>Re-opened</span>
          </b-badge>
        </template>

        <template #cell(code)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>

            <b-dropdown-item @click="invokeUpdateForm(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="remove(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Table Footer -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- ./Table Container Card -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BCard, BCardHeader, BCardBody, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormSelect, BFormSelectOption, BModal, VBModal, BButton, BTable, BBadge, BDropdown, BDropdownItem, BPagination, BSpinner } from "bootstrap-vue";
import { required, integer } from "@validations";
import store from "@/store";
import { ref, onUnmounted, onMounted, computed } from "@vue/composition-api";
import vSelect from "vue-select";
import societiesStoreModule from "@/views/cromis/society/societiesStoreModule";
import useSocietiesList from "@/views/cromis/society/useSocietiesList";

export default {
  props: {},
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    BModal,
    BButton,
    BSpinner,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
  },
  setup(props, context) {
    const dataForm = ref(null);
    const myModal = ref(null);
    const saving = ref(false);
    const serverErrors = ref(null);
    const levels = ref(null);
    const children = ref([]);
    const selections = ref([]);

    const society = ref({
      id: null,
      name: null,
      location_id: selections.value.length > 0 ? selections.value[selections.value.length - 1].model.id : null,
      code: null,
    });

    const CROMIS_SOCIETY_STORE_MODULE_NAME = "cromis-society";

    // Register module
    if (!store.hasModule(CROMIS_SOCIETY_STORE_MODULE_NAME)) store.registerModule(CROMIS_SOCIETY_STORE_MODULE_NAME, societiesStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CROMIS_SOCIETY_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_SOCIETY_STORE_MODULE_NAME);
    });

    onMounted(async () => {
      await store
        .dispatch("cromis-society/levels")
        .then((response) => {
          levels.value = response.data.levels[0];
          selections.value.push({
            level: levels.value.name.toLowerCase(),
            model: null,
            label: levels.value.name,
            child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
            locations: [],
          });
          let list = levels.value.children;

          while (list.length > 0) {
            children.value.push(list[0]);
            if (list[0].children > 0) {
              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] });
            } else {
              selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] });
            }

            list = list[0].children;
          }
        })
        .catch((error) => {
          console.log("got error", error);
        });

      await store
        .dispatch("cromis-society/locations")
        .then((response) => {
          populateSelections(response.data.locations);
        })
        .catch((error) => {
          console.log("got error", error);
        });
    });

    const populateSelections = (locations) => {
      for (let i = 0; i < locations.length; i++) {
        for (let x = 0; x < selections.value.length; x++) {
          if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
            selections.value[x].locations.push(locations[i]);
          }
        }
      }
    };

    const changeFilters = async (location, index) => {
      if (!location) {
        for (let i = index + 1; i < selections.value.length; i++) {
          selections.value[i].locations.splice(0);
          selections.value[i].model = null;
          society.value.location_id = null;
        }

        if (index > 0) {
          locationFilter.value = selections.value[index - 1].model.id;
        } else {
          locationFilter.value = null;
        }
      } else {
        if (selections.value[index + 1]) {
          selections.value[index + 1].locations.splice(0);
        }

        location.children.map((child) => {
          selections.value[index + 1].locations.push(child);
        });

        if (location.children.length > 0) {
          selections.value[index + 1].model = null;
        }

        locationFilter.value = selections.value[index].model.id;
        society.value.location_id = selections.value[index].model.id;
      }
    };

    const isAddNewUserSidebarActive = ref(false);

    const {
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,

      // Filters
      locationFilter,
      locationType,

      // UI
      // resolveUserRoleVariant,
      // resolveUserRoleIcon,
      resolveStatusVariant,
    } = useSocietiesList();

    // Methods
    const resetForm = () => {
      society.value.id = null;
      society.value.name = null;
      society.value.code = null;
      society.value.location_id = selections.value[selections.value.length - 1].model.id;
    };

    const invokeUpdateForm = (item) => {
      serverErrors.value = null;

      society.value = {
        id: item.id,
        location_id: selections.value.length > 0 ? selections.value[selections.value.length - 1].model.id : null,
        code: item.code,
        name: item.name,
      };

      myModal.value.show();
    };

    const invokeCreateForm = () => {
      society.value.id = null;
      society.value.name = null;
      society.value.code = null;
      society.value.location_id = selections.value.length > 0 ? selections.value[selections.value.length - 1].model.id : null;

      myModal.value.show();
    };

    const isFormValid = async () => {
      let isValid = false;
      await dataForm.value.validate().then((success) => {
        isValid = success;
      });

      return isValid;
    };

    const submit = (bvModalEvt) => {
      bvModalEvt.preventDefault();

      serverErrors.value = null;
      // Handle form submit
      if (society.value.id === null || society.value.id === 0) handleCreate();
      else handleUpdate(society.value);
    };

    const handleCreate = async () => {
      saving.value = true;
      const valid = await isFormValid();

      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-society/create", society.value)
        .then((response) => {
          refetch();
          saving.value = false;

          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `Society ${response.data.name} has been saved successfully!`,
            showConfirmButton: true,
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;
          if (error.response.status === 422) {
            serverErrors.value = error.response.data.errors;
          } else {
            context.root.$swal({
              icon: "error",
              title: "Server Error",
              text: "Something went wrong. See tech support",
              showConfirmButton: true,
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    };

    const handleUpdate = async (item) => {
      saving.value = true;
      const valid = await isFormValid();

      if (!valid) {
        saving.value = false;
        return;
      }

      await store
        .dispatch("cromis-society/update", { id: item.id, data: item })
        .then((response) => {
          saving = false;

          refetch();

          myModal.value.hide();

          context.root.$swal({
            icon: "success",
            text: `Changes to society ${response.data.name}/${response.data.area} has been saved successfully!`,
            showConfirmButton: false,
            timer: 5000,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          saving.value = false;

          context.root.$swal({
            icon: "error",
            title: "Server Error",
            text: "Something went wrong. See tech support",
            showConfirmButton: true,
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        });
    };

    const remove = async (id) => {
      await store
        .dispatch("cromis-society/remove", id)
        .then((response) => {
          refetch();

          context.root.$swal({
            icon: "info",
            title: "System Info",
            text: "Society has been deleted successfully",
            showConfirmButton: true,
            customClass: {
              confirmButton: "btn btn-info",
            },
            buttonsStyling: true,
          });
        })
        .catch((error) => {
          context.root.$swal({
            icon: "error",
            title: "Server Error",
            text: "Something went wrong. See tech support",
            showConfirmButton: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
        });
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      levels,
      children,
      changeFilters,
      selections,
      fetch,
      columns,
      perPage,
      currentPage,
      totalRecords,
      from,
      to,
      meta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      records,
      refetch,

      resolveStatusVariant,

      // Filters
      locationFilter,
      locationType,

      // Form Data
      society,

      // Validations
      required,
      integer,

      // Methods and Form refs
      myModal,
      saving,
      serverErrors,
      dataForm,
      resetForm,
      invokeCreateForm,
      invokeUpdateForm,
      isFormValid,
      submit,
      handleCreate,
      handleUpdate,
      remove,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
